// 路由配置文件
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash', // 使用 hash 模式
    routes: [{
            path: '/Home',
            name: 'Home',
            component: () => import('@/pages/Home.vue')
        },
        {
            path: '/qsddAbout',
            name: 'qsddAbout',
            component: () => import('@/pages/qsddAbout'),
            children: [{
                    path: 'aboutCompany',
                    component: () => import('@/pages/qsddAbout/aboutCompany'),
                },
                {
                    path: 'companyWays',
                    component: () => import('@/pages/qsddAbout/companyWays'),
                },
                {
                    path: 'workWith',
                    component: () => import('@/pages/qsddAbout/workWith'),
                },
                {
                    path: 'comoanyWays',
                    component: () => import('@/pages/qsddAbout/contactUs'),
                },
                {
                    // 重定向 公司介绍默认是aboutCompany页面
                    path: '/qsddAbout',
                    redirect: '/qsddAbout/aboutCompany'
                }
            ]
        },
        {
            path: '/qsddNews/NewsCompany',
            component: () => import('@/pages/qsddNews/NewsCompany'),
            // beforeEnter(to,from,next){
            //     window.scrollTo(0,0);
            //     next();
            // }
        },
        {
            path: '/qsddNews/NewsDetail',
            component: () => import('@/pages/qsddNews/NewsDetail'),
            // beforeEnter(to,from,next){
            //     window.scrollTo(0,0);
            //     next();
            // }
        },
        {
            path: '/qsddNews/NewsInformation',
            component: () => import('@/pages/qsddNews/NewsInformation'),
            // beforeEnter(to,from,next){
            //     window.scrollTo(0,0);
            //     next();
            // }
        },
        // {
        //     path: '/qsddNews',
        //     name: 'qsddNews',
        //     redirect: '/qsddNews/NewsCompany',
        //     // children:[{
        //     //     path: 'NewsCompany',
        //     //     name: 'NewsCompany',
        //     //     component: () => import('@/pages/qsddNews/NewsCompany'),
        //     // },
        //     // {
        //     //     path: 'NewsDetail',
        //     //     name: 'NewsDetail',
        //     //     component: () => import('@/pages/qsddNews/NewsDetail'),
        //     // },
        //     // {
        //     //     path: 'NewsInformation',
        //     //     name: 'NewsInformation',
        //     //     component: () => import('@/pages/qsddNews/NewsInformation'),
        //     // }]
        // },
        {
            path: '/qsddProduct',
            name: 'qsddProduct',
            component: () => import('@/pages/qsddProduct'),
            // beforeEnter(to,from,next){
            //     window.scrollTo(0,0);
            //     next();
            // },
            children: [{
                    path: 'productShop',
                    component: () => import('@/pages/qsddProduct/productShop'),
                },
                {
                    // 重定向 
                    path: '/qsddProduct',
                    redirect: '/qsddProduct/productShop'
                }
            ]
        },
        {
            path: '/qsddPartner',
            name: 'qsddPartner',
            component: () => import('@/pages/qsddPartner'),
            children: [{
                    path: 'partnerWithme',
                    component: () => import('@/pages/qsddPartner/partnerWithme'),
                },
                {
                    // 重定向 
                    path: '/qsddPartner',
                    redirect: '/qsddPartner/partnerWithme'
                }
            ]

        },
        {
            path: '/qsddDownload',
            name: 'qsddDownload',
            component: () => import('@/pages/qsddDownload')
        },
        // 咪语官网  2024.07.04新增
        {
            path: '/miyupc/miyupc',
            component: () => import('@/pages/miyupc/miyupc'),
        },
        //重定向到首页
        // 路由重定向：在项目跑起来的时候， 访问/，立马让他定向到首页
        {
            path: '/',
            redirect: '/Home',
        }
    ]
});


 

 
// 全局前置守卫:每次路由切换时，都会调用beforeEach
// to：要跳转到哪 from：当前导航要离开的路由信息 next是否继续跳转路由 如果要就调用next()
// router.beforeEach((to, from, next) => {
//   // 获取到前面打过标签的元素，将其scrollTop属性设置为0
//   document.documentElement.scrollTop = 0
//   // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
//   document.body.scrollTop = 0
//   // 调用继续路由跳转
//   next()
// })


export default router;