<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive >
      <router-view />
    </keep-alive>
 </transition>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
/* 版心 */
.w-pc {
  max-width: 1478px;
  // max-width: pxttrem(1478);
  min-width: 1000px;
  // min-width: pxttrem(1000);
  margin: 0 auto;
}
/* fade-transform */
/* .fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .3s;
}

.fade-transform-enter {
  opacity: .5;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* 设计稿宽度 */
// $design-width:1920;
// @function pxttrem($px){
//   @return $px/$design-width*10+rem;
// }
</style>
<style>
  body{
    font-size: 14px;
  }
</style>
